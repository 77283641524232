<template>
  <div class="main-component">
    <div class="header" id="main">
      <div class="header-video-box">
        <img :src="require('@/assets/home-component/header-video-bg-2.webp')" alt="">
        <img :src="require('@/assets/home-component/header-video-bg-2-mob.webp')" alt="">

        <!-- <video playsinline autoplay muted loop>
          <source :src="require(`@/assets/home-component/header-video.webm`)" type="video/webm">
          Your browser does not support the video tag.
        </video> -->
        <!-- <div class="logo"><img :src="require('@/assets/europopforum-logo.png')" alt=""></div> -->

      </div>
    </div>

    <div class="main-page">

      <div class="about-wrapper" id="about">
        <div class="wrapper">
          <div class="about-text">
            <p class="isTitle eb-font-black">{{generateText.HeaderTitle}}</p>
            <p class="isDesc eb-font-roman" v-html="generateText.HeaderDesc"></p>
          </div>
        </div>
      </div>

      <div class="speakers-wrapper" id="speakers">
        <h3 class="eb-font-black speakers-title">{{generateText.SpeakersTitle}}</h3>
        <SpeakersCarouselComponent :Speakers="speakers" :scrollToID="scrollToID"></SpeakersCarouselComponent>
      </div>

      <div class="flashbacks-wrapper eb-font-bold" id="flashbacks">

        <div class="wrapper">
          <h3 class="eb-font-black title">{{generateText.FlashbacksTitle}}</h3>
          <div class="flashbacks-content">
            <div class="tabs">
              <div class="tab first-day eb-font-medium text-font20" :class="{active: flashbacksTabIndex == 1}" @click="setFlashbacksTabIndex(1)" v-html="generateText.FlashbacksFirtDayTitle"></div>
              <div class="tab second-day eb-font-medium text-font20" :class="{active: flashbacksTabIndex == 2}" @click="setFlashbacksTabIndex(2)" v-html="generateText.FlashbacksSecondDayTitle"></div>
            </div>

            <div class="flashbacks-items" v-if="flashbacksTabIndex == 1">
              <!-- <div class="line"></div> -->
              <div class="flashbacks-item" v-for="item in FlashbacksDataFirstDay" :key="item.id" :class="[{left: item.id % 2 != 0}, {right: item.id % 2 == 0}]">

                <div class="flashbacks-item-inner">
                  <div class="isText">
                    <p class="head eb-font-bold text-font16">{{item.title}}</p>
                    <p class="desc eb-font-medium text-font14">{{item.desc}}</p>

                  </div>

                  <div class="isImg">
                    <img :src="require(`@/assets/home-component/agenda/new/${item.img}`)" alt="">
                  </div>
                </div>
              </div>

            </div>

            <div class="flashbacks-items" v-if="flashbacksTabIndex == 2">
              <div class="line"></div>

              <div class="flashbacks-item" v-for="item in FlashbacksDataSecondDay" :key="item.id" :class="[{left: item.id % 2 != 0}, {right: item.id % 2 == 0}]">
                <div class="flashbacks-item-inner">
                  <div class="isText">
                    <p class="head eb-font-bold text-font16">{{item.title}}</p>
                    <p class="desc eb-font-medium text-font14">{{item.desc}}</p>
                  </div>

                  <div class="isImg">
                    <img :src="require(`@/assets/home-component/agenda/new/${item.img}`)" alt="">
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="moments-wrapper" id="moments">
        <h3 class="eb-font-black moments-title">{{generateText.MomentsTitle}}</h3>
        <div class="moments-content" @click="openMomentsGallery">
          <img :src="!isXSWidth? require('@/assets/home-component/moments/moments-box-img.webp') : require('@/assets/home-component/moments/moments-box-img-m.png')" alt="">
        </div>

      </div>

      <div class="speakers-showcase-wrapper" id="showcase">
        <div class="wrapper">
          <p class="title eb-font-black">{{generateText.SpeakersShowcaseTitle}}</p>

          <div class="speakers-showcase-items">
            <div class="speakers-showcase-item" v-for="speaker in speakersShowcase" :key="speaker.id" @click="speaker.hasVideo ? openSpeakerVideoPopup(speaker,$event) : null">
              <div class="item-img">
                <img v-if="speaker.hasVideo" :src=" require('@/assets/home-component/speakers-showcase/video-btn.png') " alt="" class="speakers-showcase-item-video-btn">
                <p v-if="!speaker.hasVideo" class="speakers-showcase-item-comming-text eb-font-bold">{{generateText.ComingSoon}}</p>
                <img :src="require(`@/assets/home-component/speakers-showcase/${speaker.imageUrl}`)" alt="">
              </div>
              <div class="item-info">
                <p class="eb-font-black head"> {{speaker.head}}</p>
                <p class="eb-font-roman name"> {{speaker.name}}</p>
              </div>

            </div>
          </div>
        </div>

      </div>

      <div class="instagram-wrapper">
        <div class="wrapper-inner">
          <h3 class="eb-font-black title">{{generateText.InstragramTitle}}</h3>
          <div class="elfsight-app-43bdbd6f-78e6-4e2e-8183-7532991c8641" data-elfsight-app-lazy></div>
        </div>
      </div>

      <div class="euro-pop-app-wrapper" id="euroPopApp">
        <div class="wrapper">
          <div class="app-content">
            <div class="isLeft">
              <div class="app-content-img"><img :src="require('@/assets/footer-component/asset.webp')" alt=""></div>

            </div>
            <div class="isRight">
              <div class="right-inner">
                <p class="eb-font-black app-title">{{generateText.EuropopAppTitle}}</p>
                <p class="isHead eb-font-black">{{generateText.EuropopAppHead}}</p>
                <p class="isDesc eb-font-medium">{{generateText.EuropopAppDesc}}</p>
                <div class="isIcons">
                  <a href="https://europop.ge/" target="_blank" class="e-icon"><img :src="require('@/assets/home-component/euro-app/e-icon.png')" alt=""></a>
                  <a href="https://eb.ge/google" target="_blank" class="goggle-play-icon"><img :src="require('@/assets/home-component/euro-app/goggle-play-icon.webp')" alt=""></a>
                  <a href="https://eb.ge/appstore" target="_blank" class="app-store-icon"><img :src="require('@/assets/home-component/euro-app/app-store-icon.webp')" alt=""></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-video-wrapper">
        <div class="footer-video-box">
          <img :src="require('@/assets/footer-component/footer-video-bg.jpg')" alt="">
          <!-- <video playsinline autoplay muted loop>
            <source :src="require(`@/assets/home-component/header-video.webm`)" type="video/webm">
            Your browser does not support the video tag.
          </video> -->
          <!-- <p class="eb-font-medium title" v-html="generateText.FooterVideoTitle"></p> -->
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import TermsWrapper from "@/components/accordion/terms_wrapper.vue";
import AccordionComponent from "@/components/accordion/index.vue";
import SpeakersCarouselComponent from "@/components/speakerscarousel/index.vue";
import moment from "moment";
import * as rasterizeHTML from "rasterizehtml";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import axios from "axios";
export default {
  name: "HomeComponent",
  props: {
    siteCurrentLangGe: Boolean,
    isMobWidth: Boolean,
    isXSWidth: Boolean,
    generateText: Object,
    FlashbacksDataFirstDay: Array,
    FlashbacksDataSecondDay: Array,
    speakers: Array,
    showMomentsGallery: Boolean,
    openMomentsGallery: Function,
    closeMomentsGallery: Function,
    showSpeakerVideoPopup: Boolean,
    openSpeakerVideoPopup: Function,
    closeSpeakerVideoPopup: Function,
    ToggleSpeakerVideoPopup: Function,
    speakersShowcase: Array,
    scrollToID: Function,
  },
  components: {
    TermsWrapper,
    AccordionComponent,
    Swiper,
    SwiperSlide,
    SpeakersCarouselComponent,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      flashbacksTabIndex: 1,
      PopupSpeaker: null,
    };
  },
  methods: {
    setFlashbacksTabIndex(tab) {
      this.flashbacksTabIndex = tab;
    },
  },
  computed: {},
  beforeMount() {},
  mounted() {},
};
</script>
<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";
.text-uppercase {
  text-transform: uppercase;
  -moz-font-feature-settings: "case";
  -webkit-font-feature-settings: "case";
  font-feature-settings: "case" on;
}
.main-component {
  width: 100%;
  min-height: 100%;

  .header {
    position: relative;
    z-index: 0;
    margin-top: 100px;
    .logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        max-width: 929px;
        @include interpolate(max-width, 320px, 1920px, 170px, 929px);
        height: auto;
        display: block;
      }

      @media only screen and (max-width: 768px) {
        img {
          content: url("../../assets/europopforum-logo-m.png");
        }
      }
    }

    .header-video-box {
      position: relative;
      // padding-bottom: 56.5%;
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
        display: block;
        &:last-child{
          display: none;
        }
        @media only screen and (max-width:1200px){
          &:first-child{
            display: none;
          }
          &:last-child{
            display: block;
          }
        }
      }
    }
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      outline: none;
      border: none;
    }
    video {
      width: 100%;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 56px;
    }
  }
  .main-page {
    background-color: transparent;
    color: #000;
    width: 100%;
    margin: 0 auto;

    .about-wrapper {
      width: 100%;
      margin: 0 auto;
      margin-top: 96px;
      .about-text {
        width: 100%;
        max-width: 1240px;
        text-align: center;
        padding: 0px;
        margin-left: auto;
        margin-right: auto;
        .isTitle {
          background: linear-gradient(96deg, #231f20 50.68%, #f05a22 95.29%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 36px;
          display: inline-block;
          margin-left: auto;
          margin-right: auto;
          @include interpolate(font-size, 375px, 1920px, 20px, 36px);
          line-height: 1.1;
          padding: 0px;
          margin-bottom: 44px;
        }
        .isDesc {
          color: #000;
          font-size: 24px;
          @include interpolate(font-size, 375px, 1920px, 13px, 24px);

          margin-bottom: 0px;
          line-height: 1.2;
          padding: 0px;
        }
      }

      @media only screen and (max-width: 768px) {
        margin-top: 44px;
        .about-text {
          .isTitle {
            margin-bottom: 16px;
          }
        }
      }
    }
    .flashbacks-wrapper {
      position: relative;
      width: 100%;
      padding: 0;
      margin-top: 96px;
      z-index: 0;
      margin-bottom: 96px;
      text-align: center;
      @media only screen and (max-width: 768px) {
        margin-top: 44px;
        margin-bottom: 24px;
      }
      .title {
        background: linear-gradient(97deg, #231f20 53.77%, #f05a22 94.23%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        @include interpolate(font-size, 375px, 1920px, 20px, 36px);
        text-align: center;
        margin-bottom: 0px;
        @media only screen and (max-width: 768px) {
          margin-bottom: 0px;
        }
      }
      .flashbacks-content {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        .tabs {
          display: flex;
          justify-content: center;
          align-items: center;

          .tab {
            padding: 24px 0px 8px 0px ;
            margin: 0px 8px 36px 8px;
            color: #000;
            cursor: pointer;
            &.active {
              font-family: "Helvetica-Black" !important;
              background: linear-gradient(
                97deg,
                #231f20 53.77%,
                #f05a22 94.23%
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              border-bottom: 1px solid black;
            }
            &.first-day {
            }
            &.second-day {
            }
            @media only screen and (max-width: 768px) {
              padding: 12px 8px;
              font-size: 16px;
              .eb-font-black{
                font-family: 'Helvetica-Medium' !important;
              }
              &.active{
                font-family: 'Helvetica-Black' !important;
                .eb-font-black{
                font-family: 'Helvetica-Black' !important;
              }
              }
            }
          }
        }

        .flashbacks-items {
          position: relative;
          // max-width: 1240px;
          max-width: 100%;
          margin: 0 auto;
          margin-top: 52px;
          &::after {
            content: "";
            position: absolute;
            width: 2px;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: linear-gradient(
              180deg,
              rgba(241, 242, 242, 0) 0%,
              #f1f2f2 12.31%,
              #f1f2f2 87.5%,
              rgba(241, 242, 242, 0) 100%
            );
          }
          .flashbacks-item {
            padding: 10px 40px;
            position: relative;
            background-color: inherit;
            width: 50%;
            display: flex;
            margin-top: -52px;
            &::after {
              content: "";
              position: absolute;
              // width: 25px;
              // height: 25px;
              right: -5px;
              // background-color: white;
              // border: 4px solid #ff9f55;
              width: 10px;
              height: 10px;
              // border-radius: 19px;
              border: 2px solid #f1f2f2;
              background: #f15a22;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 50%;
              z-index: 1;
            }
            &.left {
              left: 0;
              justify-content: flex-end;
              &::before {
                content: " ";
                height: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                width: 0;
                z-index: 1;
                right: 32px;
                border: 8px solid #fff;
                // border-color: transparent transparent transparent #fff;
                border-radius: 5px;
              }
              .flashbacks-item-inner {
                justify-content: flex-end;
              }
            }
            &.right {
              left: 50%;
              justify-content: flex-start;
              &::before {
                content: " ";
                height: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                width: 0;
                z-index: 1;
                left: 32px;
                border: 8px solid #fff;
                // border-color: transparent #fff transparent transparent;
                border-radius: 5px;
              }
              &::after {
                left: -5px;
              }
              .flashbacks-item-inner {
                justify-content: flex-start;
                .isText {
                  padding-left: 12px;
                  padding-right: 0px;
                }
                .isImg {
                  order: -1;
                }
              }
            }
          }
          .flashbacks-item-inner {
            padding: 16px;
            background-color: white;
            position: relative;
            border-radius: 6px;
            display: inline-flex;
            align-items: stretch;
            text-align: left;
            .isText {
              width: auto;

              padding-right: 12px;
              padding-left: 0px;
              .head {
                margin-bottom: 12px;
              }
              .desc {
                margin-bottom: 0px;
                @media only screen and (max-width:1200px){
                  font-family: 'Helvetica-Roman' !important;
                }
              }
            }
            .isImg {
              width: 100px;
              min-width: 100px;
              order: 1;
              border-radius: 2px;
              img {
                max-width: 100%;
                width: 100%;
                // height: auto;
                height: 100%;
                display: block;
                object-fit: cover;
                border-radius: 2px;
              }
            }
          }
          @media screen and (max-width: 1200px) {
            margin-top: 0;
            &::after {
              left: 0px;
            }
            .flashbacks-item {
              width: 100%;
              padding-left: 20px;
              padding-right: 0px;
              padding-top: 4px;
              padding-bottom: 4px;
              margin-top: 0;
              &.left {
                justify-content: flex-start;
                &::before {
                  left: 13px;
                }
                &::after {
                  left: -5px;
                }
                .flashbacks-item-inner {
                  justify-content: flex-start;
                  .isText {
                    padding-left: 12px;
                    padding-right: 0px;
                  }
                  .isImg {
                    order: -1;
                  }
                }
              }
              &.right {
                left: 0%;
                &::before {
                  left: 13px;
                  right: unset;
                }
                &::after {
                  left: -5px;
                }
                .flashbacks-item-inner {
                  justify-content: flex-start;
                  .isText {
                    padding-left: 12px;
                    padding-right: 0px;
                  }
                  .isImg {
                    order: -1;
                  }
                }
              }
            }
            .flashbacks-item-inner {
              padding: 12px;
              .isText {
                .head {
                  font-size: 12px;
                }
                .desc {
                  font-size: 12px;
                }
              }
            }
          }
          @media screen and (max-width: 768px) {
            .flashbacks-item-inner {
              .isImg {
                width: 84px;
                min-width: 84px;
              }
            }
          }
          @media screen and (max-width: 600px) {
          }
        }
      }
    }

    .speakers-wrapper {
      width: 100%;
      padding: 0;
      margin-top: 96px;

      position: relative;
      text-align: center;

      @media only screen and (max-width: 768px) {
        margin-top: 44px;
      }
      .desc-mob-wrapper {
        background: linear-gradient(
          180deg,
          rgba(217, 224, 243, 0.6) 0%,
          rgba(174, 112, 255, 0.6) 100%
        );
        z-index: 999999;
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        .close-btn {
          position: absolute;
          width: 14px;
          height: 14px;
          right: 12px;
          top: 12px;
          color: #000;
          cursor: pointer;
          background: url("../../assets/home-component/close-btn.png") no-repeat
            center;
          background-size: contain;
        }
        .desc-mob {
          background-position: center;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          background-color: #fff;
          color: #000;
          width: 100%;
          margin: 0 3%;
          padding: 40px 36px;
          max-width: 100%;
          display: flex;
          flex-direction: column;
          height: auto;
          max-height: 80vh;
          position: relative;
          overflow: hidden;
          overflow-y: scroll;

          @-moz-document url-prefix() {
            scrollbar-width: thin;
            scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.2);
          }

          scroll-behavior: smooth;
          &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
          }
          &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.2);
          }
          &::scrollbar-track {
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.2);
          }
          &::-webkit-scrollbar {
            width: 0px !important;
          }
          .desc-mob-name {
            font-size: 20px;
            margin-bottom: 12px;
          }
          .desc-mob-description {
            font-size: 14px;
          }
        }
      }
      .speakers-title {
        background: linear-gradient(97deg, #231f20 53.77%, #f05a22 94.23%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
        @include interpolate(font-size, 375px, 1920px, 20px, 36px);
        text-align: center;
        margin-bottom: 43px;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        @media only screen and (max-width: 768px) {
          margin-bottom: 32px;
        }
      }
      .speakers-items {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .speakers-item {
          width: calc(25% - 40px);
          // min-height: 454px;
          margin: 20px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          // background-color: #f1f3f8;
          border-radius: 8px;
          cursor: pointer;
          position: relative;
          -webkit-transition: -webkit-transform 0.4s;
          transition: -webkit-transform 0.4s;
          -o-transition: transform 0.4s;
          transition: transform 0.4s;
          transition: transform 0.4s, -webkit-transform 0.4s;
          -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
          @media only screen and (max-width: 1366px) {
            width: calc(33.333% - 40px);
            margin: 20px;
          }
          @media only screen and (max-width: 992px) {
            width: calc(50% - 40px);
            margin: 20px;
          }
          @media only screen and (max-width: 768px) {
            width: calc(50% - 6px);
            margin: 3px;
            min-height: 215px;
            -webkit-transition: unset;
            -o-transition: unset;
            transition: unset;
            -webkit-transform-style: unset;
            transform-style: unset;
            &:nth-child(even) {
              margin-right: 0;
            }
            &:nth-child(odd) {
              margin-left: 0;
            }
          }

          .item-img {
            img {
              max-width: 100%;
              width: 100%;
              height: auto;
              // min-height: 324px;
              // object-fit: cover;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              @media only screen and (max-width: 768px) {
                max-width: 100%;
                width: 100%;
                height: auto;

                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
              }
            }
          }
        }
      }
    }

    .speakers-showcase-wrapper {
      text-align: center;
      padding: 0px;
      margin-top: 96px;

      @media only screen and (max-width: 768px) {
        margin-top: 44px;
      }
      .wrapper {
      }
      .title {
        background: linear-gradient(97deg, #231f20 53.77%, #f05a22 94.23%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
        @include interpolate(font-size, 375px, 1920px, 20px, 36px);
        text-align: center;
        margin-bottom: 44px;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        @media only screen and (max-width: 768px) {
          margin-bottom: 16px;
        }
      }
      .speakers-showcase-items {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .speakers-showcase-item {
          width: calc(25% - 24px);
          // min-height: 314px;
          margin: 12px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          border-radius: 5px;
          background: #f1f3f8;
          cursor: pointer;
          position: relative;

          @media only screen and (max-width: 1366px) {
            width: calc(33.333% - 24px);
            margin: 12px;
          }

          @media only screen and (max-width: 768px) {
            width: calc(50% - 5px);
            margin: 2.5px;

            &:nth-child(even) {
              margin-right: 0;
            }
            &:nth-child(odd) {
              margin-left: 0;
            }
          }

          .item-img {
            position: relative;
            img {
              max-width: 100%;
              width: 100%;
              height: 219px;
              object-fit: cover;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            }
            .speakers-showcase-item-video-btn {
              display: inline-block;
              position: absolute;
              width: 92px;
              height: 92px;
              @include interpolate(width, 375px, 1920px, 38px, 96px);
              @include interpolate(height, 375px, 1920px, 38px, 96px);
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            .speakers-showcase-item-comming-text {
              position: absolute;
              display: inline-block;
              width: auto;
              height: auto;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
              font-size: 24px;
              margin: 0;
              @media only screen and (max-width: 768px) {
                font-size: 12px;
              }
            }
            @media only screen and (max-width: 768px) {
              img {
                height: 104px;
              }
            }
          }
          .item-info {
            padding: 12px 24px;
            text-align: left;
            //min-height: 95px;
            .head {
              font-size: 16px;
              @include interpolate(font-size, 375px, 1920px, 12px, 16px);
              margin-bottom: 8px;
            }
            .name {
              margin-bottom: 0;
              font-size: 14px;
              @include interpolate(font-size, 375px, 1920px, 12px, 14px);
            }
            @media only screen and (max-width: 768px) {
              padding: 8px 8px;
              // min-height: 52px;
            }
          }
        }
      }
    }
    .moments-wrapper {
      width: 100%;
      padding: 0px 0px;
      margin-top: 96px;
      text-align: center;
      .moments-title {
        background: linear-gradient(97deg, #231f20 53.77%, #f05a22 94.23%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
        @include interpolate(font-size, 375px, 1920px, 20px, 36px);
        text-align: center;
        margin-bottom: 44px;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        @media only screen and (max-width: 768px) {
          margin-bottom: 16px;
        }
      }
      .moments-content {
        cursor: pointer;
        img {
          max-width: 100%;
          height: auto;
          display: block;
          margin: 0 auto;
        }
      }
      @media only screen and (max-width: 768px) {
        margin-top: 44px;
      }
    }

    .euro-pop-app-wrapper {
      width: 100%;
      padding: 0;
      margin-top: 96px;

      @media only screen and (max-width: 768px) {
        margin-top: 44px;
      }
      .app-title {
        background: linear-gradient(97deg, #231f20 53.77%, #A53106 94.23%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        font-size: 48px;
        @include interpolate(font-size, 375px, 1920px, 20px, 48px);
        text-align: left;
        margin-bottom: 24px;
        @media only screen and (max-width: 768px) {
          margin-bottom: 16px;
        }
      }
      .app-content {
        display: flex;
        align-items: stretch;
        justify-content: center;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;

        .isLeft {
          width: 45%;
          .app-content-img {
            img {
              max-width: 100%;
              height: auto;
            }
          }
          @media only screen and (max-width: 768px) {
            .app-content-img {
              text-align: center;
              img {
                content: url("../../assets/footer-component/asset-m.webp");
              }
            }
          }
        }
        .isRight {
          width: 55%;
          align-self: center;
          .right-inner {
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 15px;
            .isHead {
              font-size: 24px;
              margin-bottom: 32px;
              @media only screen and (max-width: 768px) {
                font-size: 14px;
                margin-bottom: 10px;
              }
            }
            .isDesc {
              font-size: 20px;
              margin-bottom: 60px;
              @media only screen and (max-width: 768px) {
                font-size: 12px;
                margin-bottom: 10px;
              }
            }
            .isIcons {
              display: flex;
              align-items: stretch;
              justify-content: flex-start;
              a,
              p {
                display: block;
                &.e-icon {
                  margin-right: 25px;
                  img {
                    height: 68px;
                    max-width: 100%;
                    min-width: 68px;
                  }

                  @media only screen and (max-width: 768px) {
                    margin-right: 7px;
                    margin-bottom: 0;
                    img {
                      width: 20.8px;
                      min-width: 20.8px;
                      height: 20.8px;
                    }
                  }
                }
                &.goggle-play-icon {
                  margin-right: 17px;
                  @media only screen and (max-width: 768px) {
                    margin-right: 5px;
                    img {
                    
                      max-width: 100%;
                    }
                  }
                }
                &.app-store-icon {
                  margin-right: 0px;
                  @media only screen and (max-width: 768px) {
                    margin-right: 0px;
                    img {
                     
                      max-width: 100%;
                    }
                  }
                }
                img {
                  height: 68px;
                  max-width: 100%;

                  @media only screen and (max-width: 768px) {
                    height: 20.8px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .instagram-wrapper {
      width: 100%;
      padding: 0;
      margin-top: 96px;
      text-align: center;

      @media only screen and (max-width: 1200px) {
        //    margin-bottom: 44px;
      }
      @media only screen and (max-width: 768px) {
        margin-top: 44px;
      }
      .wrapper-inner {
        width: 100%;
        position: relative;
      }
      .title {
        background: linear-gradient(97deg, #231f20 53.77%, #f05a22 94.23%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
        @include interpolate(font-size, 375px, 1920px, 20px, 36px);
        text-align: center;
        margin-bottom: 44px;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        @media only screen and (max-width: 768px) {
          margin-bottom: 16px;
        }
      }
    }
    .footer-video-wrapper {
      width: 100%;
      padding: 0;
      margin-top: 96px;
      position: relative;

      @media only screen and (max-width: 768px) {
        margin-top: 44px;
      }
      .title {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 128px;

        @include interpolate(font-size, 375px, 1920px, 40px, 128px);
        text-align: center;
        margin-bottom: 0px;
        color: #fff;
        @media only screen and (max-width: 768px) {
          margin-bottom: 0px;
        }
      }
      .footer-video-box {
        position: relative;
        background: #f1f3f8;
        // padding-bottom: 56.5%;
        img {
          max-width: 100%;
          width: 100%;
          height: auto;
          display: block;
        }
      }
      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        outline: none;
        border: none;
      }
      video {
        width: 100%;
      }
    }
  }
}
</style>
