<template>
  <div class="speaker-component">
    <div class="wrapper">
      <!-- <h4>{{this.$route.params.slug}}</h4>
      <h4>{{slug}}</h4> -->
      <div class="speaker-page-inner" v-if="SelectedSpeaker">
        <div class="isLeft">
          <div class="isNameTop" :class="slug">
            <p class="name eb-font-black">{{speakerName(SelectedSpeaker.name)}}</p>
            <p class="eb-font-bold company" v-if="slug !== 'dudey'"> {{SelectedSpeaker.shortDescription}} <br> {{SelectedSpeaker.position}} {{SelectedSpeaker.company}}</p>
          </div>
          <div class="isNameBottom">
            <img :src="require(`@/assets/home-component/speakers/speaker-page/${SelectedSpeaker.pagePhoto}`)" alt="">
            <p class="name eb-font-black">{{speakerLastName(SelectedSpeaker.name)}}</p>
            <p class="eb-font-bold company" v-if="slug === 'dudey'"> {{SelectedSpeaker.shortDescription}} <br> {{SelectedSpeaker.position}} {{SelectedSpeaker.company}}</p>
          </div>
          <div class=" isDesc" :class="{'has-tooltip': showSpeakerDesc && SelectedSpeaker.tooltipDescription}">
            <p class="eb-font-medium" v-html="SelectedSpeaker.description"></p>
          </div>
          <div class="isSliderDesc desk" v-if="showSpeakerDesc && SelectedSpeaker.tooltipDescription">
            <p class="eb-font-roman" v-html="SelectedSpeaker.tooltipDescription"></p>
          </div>

        </div>
        <div class="isRight">
          <div class="images">
            <img :src="require(`@/assets/home-component/speakers/speaker-page/${SelectedSpeaker.images}`)" alt="" class="speaker-img">
            <!-- <a :href="SelectedSpeaker.videoLink" target="_blank" v-if="SelectedSpeaker.hasVideo"> -->
            <div @click="SelectedSpeaker.hasVideo ? openSpeakerPageVideoPopup(SelectedSpeaker,$event) : null" v-if="SelectedSpeaker.hasVideo">
              <div class="video-click-area" :class="[{'gogi-gvakharia': slug === 'gogi-gvakharia' },
              {'matthew-janney': slug === 'matthew-janney' },
              {'nika-amashukeli': slug === 'nika-amashukeli' },
              {'patrick-kluivert': slug === 'patrick-kluivert' },
              {'mary-pilon': slug === 'mary-pilon' },
              {'adam-neuhaus': slug === 'adam-neuhaus' }
              ]"></div>
            </div>
            <!-- </a> -->
          </div>
        </div>
        <div class="isSliderDesc mob" v-if="showSpeakerDesc && SelectedSpeaker.tooltipDescription">
          <p class="eb-font-roman" v-html="SelectedSpeaker.tooltipDescription"></p>
        </div>
      </div>
      <div class="slider">
        <SpeakerPageCarouselComponent :Speakers='speakers' :selectedSpeaker="SelectedSpeaker" @slideChanged='showSelectedSpeakerDesc' :scrollToID='scrollToID'></SpeakerPageCarouselComponent>
      </div>
    </div>
  </div>

</template>

<script>
// import { mapGetters } from "vuex";
import SpeakerPageCarouselComponent from "@/components/speakerpagecarousel/index.vue";
export default {
  name: "SpeakerComponent",
  props: {
    slug: String,
    siteCurrentLangGe: Boolean,
    isMobWidth: Boolean,
    isXSWidth: Boolean,
    generateText: Object,
    FlashbacksDataFirstDay: Array,
    FlashbacksDataSecondDay: Array,
    speakers: Array,
    showMomentsGallery: Boolean,
    openMomentsGallery: Function,
    closeMomentsGallery: Function,
    showSpeakerVideoPopup: Boolean,
    openSpeakerVideoPopup: Function,
    closeSpeakerVideoPopup: Function,
    ToggleSpeakerVideoPopup: Function,
    showSpeakerPageVideoPopup: Boolean,
    openSpeakerPageVideoPopup: Function,
    closeSpeakerPageVideoPopup: Function,
    ToggleSpeakerPageVideoPopup: Function,
    speakersShowcase: Array,
    scrollToID: Function,
  },
  components: {
    SpeakerPageCarouselComponent,
  },
  data() {
    return { selectedSpeakerId: 0 };
  },
  methods: {
    FormatSlug(name) {
      return name.split(".")[0].toLowerCase().replace(" ", "-");
    },
    speakerName(v) {
      return v.split(" ")[0];
    },
    speakerLastName(v) {
      return v.split(" ")[1];
    },
    showSelectedSpeakerDesc(index) {
      this.selectedSpeakerId = index;
    },
  },
  computed: {
    showSpeakerDesc() {
      return this.SelectedSpeaker.id - 1 == this.selectedSpeakerId;
    },
    SelectedSpeaker() {
      // console.log("SELECTED:", this.speakers);
      return this.speakers.find((x) => this.FormatSlug(x.photo) == this.slug);
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.selectedSpeakerId = this.SelectedSpeaker.id - 1;
  },
  updated() {},
};
</script>

<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";
.speaker-component {
  width: 100%;
  min-height: 100%;
  padding-top: 100px;
  // padding-bottom: 100px;
  background: url("../../assets/home-component/speakers/new/speaker-page-bg.png")
    no-repeat;
  background-size: 100% 100%;
  padding: 196px 0 44px 0;
  .speaker-page-inner {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 16px;
    .isLeft {
      width: calc(50% - 0px);
      // margin-right: 45px;
      // max-width: 540px;
      .isNameTop {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
          margin: 0;
        }
        .name {
          background: linear-gradient(97deg, #231f20 53.77%, #f05a22 94.23%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 76px;
          margin-right: 32px;
          @include interpolate(font-size, 375px, 1920px, 26px, 76px);

          @media only screen and (max-width: 768px) {
            min-width: unset;
            margin-right: 8px;
          }
        }
        .company {
          font-size: 16px;
          @include interpolate(font-size, 375px, 1920px, 12px, 16px);
        }
        &.gogi-gvakharia {
        }
        &.leonor-rothes {
          .company {
            max-width: 260px;
          }
        }
      }
      .isNameBottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
          margin: 0;
        }
        .name {
          background: linear-gradient(97deg, #231f20 53.77%, #f05a22 94.23%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 76px;
          @include interpolate(font-size, 375px, 1920px, 26px, 76px);
          margin-left: 24px;
          @include interpolate(margin-left, 375px, 1920px, 12px, 24px);
        }
        img {
          max-width: 96px;
          @include interpolate(max-width, 375px, 1920px, 36px, 96px);
          height: auto;
        }
        .company {
          font-size: 16px;
          @include interpolate(font-size, 375px, 1920px, 12px, 16px);
        }
      }
      .isDesc {
        margin-top: 44px;
        color: #000;
        margin-bottom: 20px;
        p {
          margin: 0;
          font-size: 16px;
          @include interpolate(font-size, 375px, 1920px, 12px, 16px);
        }
        a {
          color: #000;
          text-decoration: underline !important;
        }
        &.has-tooltip {
          margin-bottom: 89px;
          @media only screen and (max-width: 768px) {
            margin-bottom: 0px;
          }
        }
      }
    }
    .isRight {
      width: calc(50% - 90px);
      margin-left: 50px;
      max-width: 580px;
      margin-bottom: 40px;
      .images {
        position: relative;
        .speaker-img {
          img {
            max-width: 100%;
            height: auto;
          }
        }
        .video-click-area {
          cursor: pointer;
          position: absolute;
          width: 50%;
          height: 50%;
          right: unset;
          left: 0;
          bottom: 0;
          top: unset;
          &.gogi-gvakharia {
            bottom: unset;
            top: 0;
          }
          &.matthew-janney {
            right: 0;
            left: unset;
          }
          &.nika-amashukeli {
            width: 80%;
            left: 15%;
            top: 0;
            bottom: unset;
          }
          &.patrick-kluivert {
            left: 0%;
            top: 0;
            bottom: unset;
          }
          &.mary-pilon {
            width: 80%;
            left: 10%;
          }
          &.adam-neuhaus {
            right: 0;
            left: unset;
            bottom: unset;
            top: 20%;
          }
        }
      }
    }
    .isSliderDesc {
      position: relative;
      text-align: left;
      padding: 12px 16px;
      border-radius: 5px;
      font-size: 16px;
      color: #fff;
      z-index: 9;
      max-width: 375px;
      width: 100%;
      // min-height: 70px;
      height: max-content;
      background: #f05a27;

      &::after {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background: inherit;
        border-radius: 3px;
        position: absolute;
        top: 100%;
        left: 10%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &.desk {
        display: block;
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      &.mob {
        display: none;
        @media only screen and (max-width: 768px) {
          display: block;

          display: block;
          align-self: flex-start;
          margin-bottom: 3px;
        }
      }
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .isLeft {
        width: calc(100% - 0px);
        margin-right: 0px;
        max-width: unset;
        .isDesc {
          margin-top: 16px;
          margin-bottom: 0;
        }
      }
      .isRight {
        width: calc(100% - 0px);
        margin-left: 0px;
        // max-width: 342px;
        margin-top: 16px;
        margin-bottom: 40px;
      }
    }
    @media only screen and (max-width: 600px) {
    }
  }
  .slider {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0px;
  }
  @media only screen and (max-width: 768px) {
    padding: 72px 0 16px 0;
  }
  @media only screen and (max-width: 600px) {
  }
}
</style>
