<template>
  <footer class="footer-container">
    <div class="wrapper">
      <div class="footer-wrapper text-font14">
        <div class="leftSide">
          <div>
            <a href="https://europop.ge/" target="_blank">
              <img :src="require('@/assets/footer-component/footer-logo.png')" alt="" />
            </a>
          </div>
        </div>
        <div class="rightSide">
          <!-- <a href="https://eb.ge/topvolunteer" target="_blank" class="volun-link eb-font-medium text-font20">
            Volunteers
          </a> -->
          <span class="eb-font-medium text-font20">{{generateText.FooterText}}</span>
          <div class="social">
            <a href="https://www.instagram.com/europopforum/" target="_blank">
              <img :src="require('@/assets/footer-component/instagram.png')" alt="" />
            </a>
            <a href="https://www.facebook.com/europopforum" target="_blank">
              <img :src="require('@/assets/footer-component/facebook.png')" alt="" />
            </a>
            <a href="https://twitter.com/europopforum" target="_blank">
              <img :src="require('@/assets/footer-component/twitter.png')" alt="" />
            </a>

          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import router from "../../router";
export default {
  name: "FooterComponent",
  props: { generateText: Object },
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
.footer-container {
  background-color: #f1f3f8;
  padding: 0px;
  margin-bottom: 0px;
  color: #000;
  box-shadow: 0 0 20px rgba(155, 169, 208, 0.4);

  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 104px;
    @media only screen and (max-width: 768px) {
      min-height: 60px;
    }
  }
  .leftSide {
    display: flex;
    align-items: center;
    img {
      max-width: 200px;
      height: auto;
    }
    @media only screen and (max-width: 768px) {
      img {
        max-width: 100px;
        height: auto;
      }
    }
  }
  .rightSide {
    display: flex;
    align-items: center;
    .volun-link {
      text-decoration: underline !important;
      color: #f05a27;
      display: block;
      font-size: 20px;
      margin-right: 33px;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
        margin-right: 20px;
      }
      @media only screen and (max-width: 370px) {
        font-size: 15px;
        margin-right: 8px;
      }
    }
    span {
      display: inline-block;
      margin-right: 12px;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    .social {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 8px;
        max-width: 48px;
        height: auto;
      }
      @media only screen and (max-width: 768px) {
        img {
          margin-right: 5px;
          max-width: 24px;
          height: auto;
        }
      }
    }
  }
}
</style>
