<template>

  <div class="speakers-swiper-outer">

    <div class="speakers-swiper-btns">
      <div class="speakers-swiper-btns-inner">
        <div class="speakers-prev swiper-button-prev" slot="button-prev"></div>
        <div class="speakers-next swiper-button-next" slot="button-next"></div>
      </div>
    </div>

    <swiper ref="thumbsSwiperSpeakers" :options="speakersSwiperOptions" class="speakers-thumbs-wrapper" @click="clickedToSlide">
      <swiper-slide v-for="(item, index) in Speakers" :key="index" class="custom-swiper-slide">
        <router-link class="speakers-thumb" :to="{
                  name: 'Speaker',
                  params: {
                    slug: FormatSlug(item.photo),
                  },
                }">
          <img :src="require(`@/assets/home-component/speakers/new/${item.photo}`)" />

          <div v-if="item.active" class="isDescription font-hl-roman text-font13" :class="[item.color]">
            <p>{{item.tooltipDescription}}</p>
          </div>
        </router-link>
      </swiper-slide>
      <swiper-slide class="empty-slide"> </swiper-slide>
    </swiper>

  </div>

</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
export default {
  name: "SpeakersCarouselComponent",
  props: { Speakers: Array, scrollToID: Function },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      paginationEl: null,
    };
  },
  methods: {
    getText(key) {
      return ContentService.getContentByKey(key);
    },
    FormatSlug(name) {
      return name.split(".")[0].toLowerCase().replace(" ", "-");
    },
    clickedToSlide() {
      setTimeout(() => {
        this.scrollToID("#app");
      }, 100);
    },
  },

  computed: {
    thumbsSwiperSpeakers() {
      return this.$refs.thumbsSwiperSpeakers.$swiper;
    },

    speakersSwiperOptions() {
      return {
        slidesPerView: "auto",
        spaceBetween: 12,
        initialSlide: 0,
        observer: true,
        observeParents: true,
        loop: false,
        // autoplay: true,
        //   autoplay: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },

        navigation: {
          nextEl: ".speakers-next",
          prevEl: ".speakers-prev",
        },
        breakpoints: {
          // 500: {
          //   slidesPerView: 2,
          // },
          768: {
            // slidesPerView: 3,
            spaceBetween: 24,
          },
          // 1366: {
          //   slidesPerView: 4,
          // },
        },
      };
    },
  },
  beforeMount() {},
};
</script>
<style lang="scss">
.speakers-swiper-btns {
  position: absolute;
  width: 100%;
  top: -85px;
  left: 0;
  margin-left: -44px;
  .speakers-swiper-btns-inner {
    width: 100%;
    max-width: 1700px;
    min-width: 320px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }
  .swiper-button-prev.speakers-prev {
    width: 88px;
    height: 24px;
    background-image: url("../../assets/home-component/speakers/new/arrow-prev.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &.swiper-button-disabled {
      opacity: 0.3;
      cursor: default;
    }
  }
  .swiper-button-next.speakers-next {
    width: 88px;
    height: 24px;
    background-image: url("../../assets/home-component/speakers/new/arrow-next.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &.swiper-button-disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  @media only screen and (max-width: 768px) {
    top: -35px;
    margin-left: -16px;
    .swiper-button-prev.speakers-prev {
      width: 36px;
      height: 12px;

      // background-size: contain;
    }
    .swiper-button-next.speakers-next {
      width: 36px;
      height: 12px;
    }
  }
}
.swiper-scrollbar {
  margin-top: 14px;
  background-color: #303436;
  .swiper-scrollbar-drag {
    background-color: #f05a22;
    height: 4px;
  }
}
section {
  position: relative;
  z-index: auto;
}
.speakers-swiper-outer {
  width: 100%;
  margin-left: 44px;
  position: relative;
  padding: 103px 0px;

  @media only screen and (max-width: 768px) {
    margin-left: 16px;
    padding: 50px 0px;
    margin-bottom: 0;
    .swiper-wrapper {
      margin-bottom: 0;
    }
  }
}
.swiper-slide.custom-swiper-slide {
  display: inline-block;
  width: 300px;
  height: auto;

  @media only screen and (max-width: 768px) {
    width: 120px;
  }
}
.swiper-slide.empty-slide {
  width: 50px;
}
.speakers-thumb {
  cursor: pointer;
  position: relative;
  display: block;

  .isDescription {
    position: absolute;
    left: 0;
    text-align: left;
    padding: 10px 12px;
    border-radius: 5px;
    font-size: 13px;
    color: #fff;
    z-index: 999;
    max-width: 100%;
    width: 100%;
    min-height: 70px;
    height: max-content;
    p {
      display: block;
      -webkit-line-clamp: unset;
      -webkit-box-orient: unset;
      overflow: visible;
      text-overflow: unset;
      margin: 0;
    }
    &.red-top {
      background: #fd0458;
      top: 0;
      transform: translateY(-85%);
    }
    &.green-top {
      background: #00a8ad;
      top: 0;
      transform: translateY(-85%);
    }
    &.orange-top {
      background: #f05a27;
      top: 0;
      transform: translateY(-85%);
    }
    &.blue-top {
      background: #055fff;
      top: 0;
      transform: translateY(-85%);
    }
    &.violet-top {
      background: #ae70ff;
      top: 0;
      transform: translateY(-85%);
    }
    &.orange-bottom {
      background: #f05a27;
      bottom: 0;
      transform: translateY(85%);
      &::after {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background: inherit;
        border-radius: 5px;
        position: absolute;
        top: 0%;
        left: 85%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    &.violet-bottom {
      background: #ae70ff;
      bottom: 0;
      transform: translateY(85%);
      &::after {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background: inherit;
        border-radius: 5px;
        position: absolute;
        top: 0%;
        left: 85%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    &.red-bottom {
      background: #fd0458;
      bottom: 0;
      transform: translateY(85%);
      &::after {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background: inherit;
        border-radius: 5px;
        position: absolute;
        top: 0%;
        left: 85%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    &.green-bottom {
      background: #00a8ad;
      bottom: 0;
      transform: translateY(85%);
      &::after {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background: inherit;
        border-radius: 5px;
        position: absolute;
        top: 0%;
        left: 85%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    &.blue-bottom {
      background: #055fff;
      bottom: 0;
      transform: translateY(85%);
      &::after {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background: inherit;
        border-radius: 5px;
        position: absolute;
        top: 0%;
        left: 85%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    &::after {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background: inherit;
      border-radius: 3px;
      position: absolute;
      top: 100%;
      left: 85%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    @media only screen and (max-width: 768px) {
      font-size: 10px;
      max-width: 115%;
      width: 115%;
      height: 56px;
      min-height: unset;
      left: -7.5%;
      padding: 8px;
      transform: translateY(-89%);
      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &::after {
        left: 63%;
      }
      &.orange-bottom {
        transform: translateY(89%);
        &::after {
          left: 63%;
        }
      }
    }
  }
  img {
    border-radius: 14px;
    max-width: 300px;
    height: auto;
    display: block;
    @media only screen and (max-width: 768px) {
      max-width: 120px;
    }
  }
}
.swiper-container.speakers-thumbs-wrapper {
  overflow: visible !important;
}
</style>
