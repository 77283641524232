<template>
  <div class="tc-row" :class="{ open: isActive }">
    <div class="tc-row-head font-hl-black" @click="toggle">
      {{ title }}
    </div>
    <div class="tc-row-content">
      <div class="tc-ul font-hl-roman">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // ...
  name: "AccordionComponent",

  data() {
    return {
      isActive: this.isActiveProps,
    };
  },
  props: {
    isActiveProps: Boolean,
    title: String,
  },

  methods: {
    toggle() {
      this.isActive = this.isActive ? false : true;
    },
  },
};
</script>
<style lang="scss">
.tc-section {
  .tc-row {
    margin-bottom: 4px !important;
    cursor: pointer;
    color: #000;
    background-color: rgba(251, 251, 251, 0);
    border-radius: 8px !important;

    .tc-row-head {
      background-color: #f1f3f8;
      font-size: 36px !important;
      border-radius: 12px !important;
      position: relative;
      line-height: 1.2;
      color: #000;
      padding: 60px;

      &:before {
        content: "";
        position: absolute;
        right: 65px;
        top: calc(50% - 5px);
        width: 20px;
        height: 20px;
        border-left: 3px solid #000;
        border-bottom: 3px solid #000;
        transform: rotate(-45deg);
        transition: all 0.4s;
      }
      @media only screen and (max-width: 768px) {
        font-size: 16px !important;
        padding: 24px 16px !important;
        &:before {
          right: 15px;
          width: 10px;
          height: 10px;
          border-width: 2px;
        }
      }
    }

    .tc-row-content {
      margin-top: 0;
      margin-bottom: 0px;
      // border-bottom-left-radius: 12px;
      // border-bottom-right-radius: 12px;
      max-height: 0;
      overflow: hidden;
      transition: opacity 0.4s, max-height 0.6s;
      color: #6a6a6a;
      opacity: 0;

      .tc-ul {
        // list-style: none;
        // list-style-type: none !important;
        padding: 0px 60px;

        @media only screen and (max-width: 768px) {
          padding: 0px 16px 10px;
          //  margin-top: 16px;
        }

        p {
          // color: #ee5a22;
        }
        // li {
        //   position: relative;
        //   margin-bottom: 10px;

        //   ul {
        //     list-style: none;
        //     margin-top: 5px;
        //     margin-bottom: 20px;
        //     padding-left: 15px;

        //     li {
        //       position: relative;
        //       margin-bottom: 5px;
        //       // &:before {
        //       //   content: "";
        //       //   position: absolute;
        //       //   left: -15px;
        //       //   top: 7px;
        //       //   width: 5px;
        //       //   height: 5px;
        //       //   border-radius: 50%;
        //       //   background-color: #bbbbbb;
        //       // }
        //     }
        //   }
        //   // &:before {
        //   //   content: "";
        //   //   position: absolute;
        //   //   left: -15px;
        //   //   top: 7px;
        //   //   width: 5px;
        //   //   height: 5px;
        //   //   border-radius: 50%;
        //   //   background-color: #f46a21;
        //   // }
        // }
      }
      @media only screen and (max-width: 768px) {
        //margin-top: 14px;
      }
    }

    &.open {
      background-color: #f1f3f8;
      .tc-row-head {
        // color: #f46a21;
        //padding-bottom: 0;
        &:before {
          transform: rotate(135deg);
          top: calc(50% - 2px);
        }
      }
      .tc-row-content {
        max-height: 3000px;
        opacity: 1;
        margin-bottom: 4px;

        // ul {
        //   background-color: #fff;
        //   border-radius: 12px;
        // }
        @media only screen and (max-width: 768px) {
          margin-bottom: 2px;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      margin-bottom: 2px !important;
    }
  }
}
</style>
