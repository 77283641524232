
<template>
  <div class="moments-gallery-container">
    <div class="active-img-outer">
      <div class="moments-swiper-btns">
        <div class="moments-swiper-btns-inner">
          <div class="moments-prev swiper-button-prev" slot="button-prev"></div>
          <div class="moments-next swiper-button-next" slot="button-next"></div>
        </div>
      </div>
      <div class="active-img-prev">
        <img :src="require(`../../assets/home-component/moments/large/${PreviewIndex}.webp`)" />
      </div>
    </div>
    <div class="swiper-scrollbox">
      <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs" @click="goTo" @slideChange="onThumbnailChange">
        <swiper-slide v-for="n in 39" :key="n" class="slide" :class="`slide-${n}`"></swiper-slide>

      </swiper>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/css/swiper.css";

export default {
  name: "MomentsCarousel",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      PreviewIndex: 1,
      swiperOptionThumbs: {
        loop: true,
        // loopedSlides: 40, // looped slides should be the same
        spaceBetween: 12,
        initialSlide: 0,
        centeredSlides: true,
        slidesPerView: "auto",
        touchRatio: 0.2,
        slideToClickedSlide: true,
        navigation: {
          nextEl: ".moments-next",
          prevEl: ".moments-prev",
        },
        breakpoints: {
          500: {
            //     slidesPerView: 8,
          },
          768: {
            //   slidesPerView: 12,
          },
          1366: {
            // slidesPerView: 16,
          },
        },
      },
    };
  },
  computed: {
    swiperTop() {
      return this.$refs.swiperTop.$swiper;
    },
    swiperThumbs() {
      return this.$refs.swiperThumbs.$swiper;
    },
  },
  methods: {
    goTo(val) {
      if (val.activeIndex) {
        // this.PreviewIndex = val.realIndex ? val.realIndex + 1 : val.activeIndex;
        this.PreviewIndex = val.realIndex ? val.realIndex + 1 : 1;
      }
    },
    onThumbnailChange(val) {
      // console.log("onThumbnailChange", val.activeIndex, val.realIndex);
      this.PreviewIndex = val.realIndex ? val.realIndex + 1 : 1;
      this.$refs.swiperThumbs.$swiper.slideTo(val.activeIndex);
      // console.log("PreviewIndex", this.PreviewIndex);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.moments-gallery-container {
  background-position: center;
  border-radius: 8px;
  padding: 44px 0px 32px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  // filter: blur(20px);
  backdrop-filter: blur(10px);
  color: #000;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-y: auto;

  scroll-behavior: smooth;
  text-align: center;

  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px !important;
  }
  @media only screen and (max-width: 768px) {
    border-top: 1px solid rgba(155, 169, 208, 0.4);
    padding: 16px 0px;
    background: linear-gradient(180deg, #d9e0f3 0%, #f2f4fb 100%);
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
}
.moments-swiper-btns {
  position: absolute;
  width: calc(100% + 120px);
  top: 50%;
  left: -60px;
  right: -60px;
  .moments-swiper-btns-inner {
    width: 100%;
    max-width: 1700px;
    min-width: 320px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
  .swiper-button-prev.moments-prev {
    width: 56px;
    height: 56px;
    background-image: url("../../assets/home-component/moments/moments-prev-btn.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before{
      content: "";
      position: fixed; 
      width: 100vh;
      height: 55vh;
      left: calc(49% - 100vh);
    }
    &:hover {
      opacity: 0.8;
    }
    &.swiper-button-disabled {
      opacity: 0.3;
      cursor: default;
    }
  }
  .swiper-button-next.moments-next {
    width: 56px;
    height: 56px;
    background-image: url("../../assets/home-component/moments/moments-next-btn.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer; 
    display: flex;
    justify-content: center;
    align-items: center;
    &:before{
      content: "";
      position: fixed; 
      width: 100vh;
    height: 55vh;
    right: calc(51% - 100vh);
    }
    &:hover {
      opacity: 0.8;
    }
    &.swiper-button-disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  @media only screen and (max-width: 768px) {
    .swiper-button-prev.moments-prev {
      display: none;
    }
    .swiper-button-next.moments-next {
      display: none;
    }
  }
}
.swiper-scrollbox {
  position: relative;
  width: 100%;
  height: 20%;
  max-height: 144px;
  min-height: 144px;
  @media only screen and (max-width: 768px) {
    height: calc(14% - 16px);
    max-height: 96px;
    min-height: 96px;
  }
}
.active-img-outer {
  display: inline-flex;
  height: calc(79.5% - 44px);
  justify-content: center;
  position: relative;
  margin-bottom: 44px;
  padding: 0 0px;
  @media only screen and (max-width: 768px) {
    height: calc(86% - 16px);
    margin-bottom: 16px;
    padding: 0 16px;
  }
}
.active-img-prev {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
  }
}

.swiper {
  // .swiper-slide {
  //   background-size: cover;
  //   background-position: center;
  //   @for $i from 1 through 39 {
  //     &.slide-#{$i} {
  //       background-image: url("../../assets/home-component/moments/small/" + $i + ".webp");
  //     }
  //   }
  // &.slide-1 {
  //   background-image: url("../../assets/home-component/moments/1.jpeg");
  // }
  // }

  // &.gallery-top {
  //   height: calc(79.5% - 44px);
  //   width: 100%;
  //   max-width: 1230px;
  //   border-radius: 5px;
  //   padding: 0 32px;
  //   margin-bottom: 44px;
  //   @media only screen and (max-width: 768px) {
  //     height: calc(86% - 16px);
  //     margin-bottom: 16px;
  //     padding: 0 16px;
  //   }
  // }
  &.gallery-thumbs {
    height: calc(20.5% - 0px);
    max-height: 144px;
    min-height: 144px;
    box-sizing: border-box;
    padding: 0;
    padding-top: 24px;
    border-top: 1px solid rgba(155, 169, 208, 0.4);
    @media only screen and (max-width: 768px) {
      height: calc(14% - 16px);
      padding-top: 16px;
      max-height: 96px;
      min-height: 96px;
    }
  }

  &.gallery-thumbs .swiper-slide {
    @for $i from 1 through 39 {
      &.slide-#{$i} {
        background-image: url("../../assets/home-component/moments/small/" + $i + ".webp");
      }
    }
    background-size: cover;
    background-position: center;
    cursor: pointer;
    width: 25%;
    height: 100%;
    max-width: 102px;
    min-width: 102px;
    border-radius: 5px;
    min-height: 100px;
    height: 100%;
    opacity: 0.8;
    @media only screen and (max-width: 768px) {
      max-width: 68px;
      min-width: 68px;
      min-height: 80px;
    }
  }
  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
}
</style>