import axios from "axios";
import LanguageService from "@/core/services/language.service";
import ContentService from "@/core/services/content.service";

export default {
  init: function (appCreated) {
    return new Promise((resolve, reject) => {
      switch (appCreated) {
        case false:
          Promise.all([
            ContentService.fetchContent(),
            LanguageService.fetchLanguages(),
          ])
            .then((values) => {
              ContentService.setContent(values[0]?.data);
              LanguageService.setLanguages(values[1]?.data);
              resolve();
            })
            .catch((err) => {
              // console.log(err);
              resolve();
            });
          break;
        case true: 
          setTimeout((_) => resolve("Mounted / shown when app ready"), 10);
      }
    });
  },
};
