<template>
  <div class="nav">
    <div class="wrapper full">
      <div class="nav-inner">

        <div class="nav-logo">
          <div @click="scrollToID('#main')">
            <router-link :to="{name: 'Home' }"> <img :src="require('@/assets/navbar-component/main-logo.png')" alt="Logo"></router-link>
          </div>
        </div>
        <div class="nav-menu-toggle" @click="toggleMenu()" :class="[{'clicked': showMobMenu}, {dn: showMomentsGallery || showSpeakerVideoPopup || showSpeakerPageVideoPopup}]"></div>
        <div class="close-moments-gallery-btn" @click="showPopup" :class="[{db: showMomentsGallery || showSpeakerVideoPopup || showSpeakerPageVideoPopup}]"></div>
        <div class="nav-menu">
          <div class="link eb-font-roman eb-font-black" @click="scrollToID('#main')">
            <router-link :to="{name: 'Home' }"><span>{{generateText.LinkMain}}</span></router-link>
          </div>
          <div class="link eb-font-roman" @click="scrollToID('#about')">
            <router-link :to="{name: 'Home' }"> <span>{{generateText.LinkAbout}}</span></router-link>
          </div>
          <div class="link eb-font-roman" @click="scrollToID('#speakers')">
            <router-link :to="{name: 'Home' }"><span>{{generateText.LinkSpeakers}}</span></router-link>
          </div>
          <div class="link eb-font-roman" @click="scrollToID('#flashbacks')">
            <router-link :to="{name: 'Home' }"><span>{{generateText.LinkFlashbacks}}</span></router-link>
          </div>
          <div class="link eb-font-roman" @click="scrollToID('#moments')">
            <router-link :to="{name: 'Home' }"><span>{{generateText.LinkMoments}}</span></router-link>
          </div>
          <div class="link eb-font-roman" @click="scrollToID('#showcase')">
            <router-link :to="{name: 'Home' }"> <span>{{generateText.LinkShowcase}}</span></router-link>
          </div>
        </div>
        <div class="translate-btn eb-font-roman text-uppercase" @click="toggleCurrentLanguage">
          <span :class="{active:!siteCurrentLangGe}">EN</span>
          <span :class="{active:siteCurrentLangGe}">GE</span>
        </div>
      </div>
    </div>
    <div class="mob-menu-wrapper" :class="{opened: showMobMenu}">
      <div class="mob-menu" :class="{opened: showMobMenu}">
        <div class="header">
          <div class="mob-menu-logo">
            <div @click="scrollToID('#main')">
              <router-link :to="{name: 'Home' }"> <img :src="require('@/assets/navbar-component/main-logo.png')" alt="Logo"></router-link>
            </div>
          </div>
          <div class="close-mob-menu-btn" @click="closeMobMenu"></div>
        </div>
        <div class="links-wrapper">
          <div class="link eb-font-medium eb-font-black" @click="scrollToID('#main')">
            <router-link :to="{name: 'Home' }"> <img :src="require('@/assets/navbar-component/main-icon.png')" alt="">
              <span>{{generateText.LinkMain}}</span></router-link>
          </div>
          <div class="link eb-font-medium" @click="scrollToID('#about')">
            <router-link :to="{name: 'Home' }"> <img :src="require('@/assets/navbar-component/about-icon.png')" alt="">
              <span>{{generateText.LinkAbout}}</span></router-link>
          </div>
          <div class="link eb-font-medium" @click="scrollToID('#speakers')">
            <router-link :to="{name: 'Home' }"> <img :src="require('@/assets/navbar-component/speakers-icon.png')" alt="">
              <span>{{generateText.LinkSpeakers}}</span></router-link>
          </div>
          <div class="link eb-font-medium" @click="scrollToID('#flashbacks')">
            <router-link :to="{name: 'Home' }"> <img :src="require('@/assets/navbar-component/flashbacks-icon.png')" alt="">
              <span>{{generateText.LinkFlashbacks}}</span></router-link>
          </div>
          <div class="link eb-font-medium" @click="scrollToID('#moments')">
            <router-link :to="{name: 'Home' }"> <img :src="require('@/assets/navbar-component/moments-icon.png')" alt="">
              <span>{{generateText.LinkMoments}}</span></router-link>
          </div>
          <div class="link eb-font-medium" @click="scrollToID('#showcase')">
            <router-link :to="{name: 'Home' }"> <img :src="require('@/assets/navbar-component/showcase-icon.png')" alt="">
              <span>{{generateText.LinkShowcase}}</span></router-link>
          </div>

        </div>
        <div class="translate-btns">
          <div class="translate-btn eb-font-medium" @click="toggleCurrentLanguage()">
            <img :src="require('@/assets/navbar-component/translate-icon.png')" alt="">
            <span :class="{active:!siteCurrentLangGe}">EN</span>
            <span :class="{active: siteCurrentLangGe}">GE</span>
          </div>

        </div>
        <div class="microphone">
          <img :src="require('@/assets/navbar-component/microphone.png')" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import $ from "jquery";
import moment from "moment";
export default {
  name: "NavbarComponent",
  props: {
    showMobMenu: Boolean,
    toggleMenu: Function,
    closeMobMenu: Function,
    backToTopNav: Function,
    scrollToID: Function,
    siteCurrentLangGe: Boolean,
    setCurrentLanguage: Function,
    toggleCurrentLanguage: Function,
    ticketsSaleStart: Boolean,
    openAccreditationPopup: Function,
    openTicketsPopup: Function,
    openAboutUsPopup: Function,
    generateText: Object,
    showMomentsGallery: Boolean,
    closeMomentsGallery: Function,

    showSpeakerVideoPopup: Boolean,
    closeSpeakerVideoPopup: Function,
    showSpeakerPageVideoPopup: Boolean,
    closeSpeakerPageVideoPopup: Function,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      showTicketsNotification: false,
    };
  },
  computed: {
    ...mapGetters({
      // teamsList: "allTeams",
    }),
    currentLanguage() {
      if (this.siteCurrentLangGe) {
        return "GE";
      } else {
        return "EN";
      }
    },
  },
  methods: {
    FormatSlug(name) {
      return name.split(".")[0].toLowerCase().replace(" ", "-");
    },
    showPopup() {
      switch (true) {
        case this.showMomentsGallery:
          this.closeMomentsGallery();
          break;
        case this.showSpeakerVideoPopup:
          this.closeSpeakerVideoPopup();
          break;
        case this.showSpeakerPageVideoPopup:
          this.closeSpeakerPageVideoPopup();
          break;

        default:
          break;
      }
    },
  },

  mounted() {
    var $links = $(".nav-menu .link");
    $links.on("click", function () {
      $links.removeClass("eb-font-black");
      $(this).addClass("eb-font-black");
    });
    var $moblinks = $(".links-wrapper .link");
    $moblinks.on("click", function () {
      $moblinks.removeClass("eb-font-black");
      $(this).addClass("eb-font-black");
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/eb-mixin.scss";

.nav {
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 20px rgba(164, 176, 212, 0.5);
  .nav-inner {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 0px;
    width: 100%;
    background-color: #fff;
    min-height: 100px;
    position: relative;
    &::after {
      content: "";
      display: none;
      position: absolute;
      height: 100%;
      width: 100vw;
      background: inherit;
      top: 0;
      left: -50vw;
      right: -50vw;
      margin-right: auto;
      margin-left: auto;
      z-index: -1;
    }

    .nav-logo {
      max-width: 162px;
      align-self: center;
      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
      @media only screen and (max-width: 1280px) {
        max-width: 100px;
      }
      @media only screen and (max-width: 768px) {
        max-width: 100px;
      }
      @media only screen and (max-width: 500px) {
        max-width: 81px;
      }
    }
    .nav-menu {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: stretch;
      min-height: 100%;
      .link {
        font-size: 24px;
        @include interpolate(font-size, 1100px, 1920px, 16px, 24px);
        padding: 0px 16px;
        @include interpolate(padding-left, 1100px, 1920px, 6px, 16px);
        @include interpolate(padding-right, 1100px, 1920px, 6px, 16px);
        display: inline-flex;
        align-items: center;
        min-height: 100%;
        text-decoration: none;
        color: #000;
        position: relative;
        cursor: pointer;
        line-height: 1.2;
        a {
          color: inherit;
          &.router-link-exact-active {
          }
          &.router-link-active {
          }
        }
        @media only screen and (max-width: 1100px) {
          padding: 0 12px;
          font-size: 16px;
        }

        &:hover {
          color: #f05a22;
        }

        &.eb-font-black {
          color: #f05a22;
          font-family: "Helvetica-Black" !important;
        }
      }
      // .tickets-btn {
      //   background-color: #a4b0d4;
      //   color: rgba(255, 255, 255, 0.6);
      //   font-size: 20px;
      //   border-radius: 8px;
      //   height: 42px;
      //   min-width: 131px;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   align-self: center;
      //   margin: 0 16px;
      //   cursor: pointer;
      //   position: relative;
      //   @media only screen and (max-width: 1280px) {
      //     margin: 0;
      //     min-width: 110px;
      //     font-size: 16px;
      //   }
      //   // .tickets-notification {
      //   //   position: absolute;
      //   //   top: 100%;
      //   //   left: 50%;
      //   //   transform: translate(-50%, 25%) scale(0);
      //   //   transform-origin: top center;
      //   //   transition: all 0.25s linear;
      //   //   min-width: max-content;
      //   //   width: auto;
      //   //   min-height: 58px;
      //   //   padding: 0 20px;
      //   //   background-color: #000;
      //   //   color: rgba(255, 255, 255, 0.8);
      //   //   font-size: 16px;
      //   //   border-radius: 8px;
      //   //   display: flex;
      //   //   justify-content: center;
      //   //   align-items: center;
      //   //   &:after {
      //   //     content: "";
      //   //     position: absolute;
      //   //     top: -20px;
      //   //     width: 12px;
      //   //     height: 12px;
      //   //     display: inline-block;
      //   //     border-radius: 1px;
      //   //     border: 12px solid transparent;
      //   //     border-bottom: 12px solid #000;
      //   //   }
      //   //   &.show {
      //   //     transform: translate(-50%, 25%) scale(1);
      //   //   }
      //   // }
      //   &.active {
      //     background-color: #ae70ff;
      //     color: rgba(255, 255, 255, 1);
      //   }
      // }

      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }
    .translate-btn {
      height: 48px;
      color: #000;
      font-size: 16px;
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      align-self: center;
      margin-left: auto;
      cursor: pointer;
      position: relative;

      span {
        transition: width 0.3s, opacity 0.3s;
        position: relative;
        width: 0;
        opacity: 0;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 8px;
        margin: 0 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: rgba(164, 176, 212, 0.3);
        }
        &.active {
          // background-color: rgba(164, 176, 212, 0.3);
          width: 48px;
          overflow: auto;
          opacity: 1;
          border: 1px solid #343a3c;
        }
      }
      &:hover {
        span {
          transition: width 0.3s, opacity 0.6s;
          border: 1px solid #343a3c;
          width: 48px;
          opacity: 1;
        }
      }
      @media only screen and (max-width: 1100px) {
        display: none;
      }
    }
    .nav-menu-toggle {
      justify-content: flex-end;
      align-self: center;
      display: none;
      cursor: pointer;

      padding: 0;
      position: relative;
      z-index: 3;
      width: 24px;
      height: 17px;
      background: url("../../assets/navbar-component/menu-toggle.png") no-repeat
        center;
      background-size: contain;
      margin-top: 10px;
      &.dn {
        display: none;
      }
      @media only screen and (max-width: 1100px) {
        display: inline-flex;
      }
    }
    .close-moments-gallery-btn {
      display: none;
      position: relative;
      margin-top: 20px;
      width: 16px;
      height: 16px;
      cursor: pointer;
      background: url("../../assets/home-component/close-btn.png") no-repeat
        center;
      background-size: contain;
      &.db {
        display: inline-block;
      }
    }
    @media only screen and (max-width: 1100px) {
      padding-left: 16px;
      padding-right: 16px;
      min-height: 56px;
      // background-color: rgba(217, 224, 243, 0.8);
      background-color: #d9e0f3;
    }
  }
  .mob-menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.35s linear;
    &.opened {
      opacity: 1;
      visibility: visible;
      transition: all 0.2s linear;
    }
  }
  .mob-menu {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0%;
    background: linear-gradient(180deg, #f1f3f8 0%, #d9e0f3 100%);
    padding-left: 0px;
    transform: scaleX(0);
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    @-moz-document url-prefix() {
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.2);
    }
    scroll-behavior: smooth;
    transition: all 0.2s linear;
    transform-origin: left top;
    z-index: 99999;
    // border-radius: 0px 12px 12px 0px;
    border-radius: 0px;
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }
    &::scrollbar-track {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar {
      width: 1px !important;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 55px;
      border-bottom: 1px solid rgba(155, 169, 208, 0.5);
      padding: 0 22px 0 16px;
      .mob-menu-logo {
        max-width: 81px;
        align-self: center;
        img {
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
      .close-mob-menu-btn {
        width: 14px;
        height: 14px;

        cursor: pointer;
        background: url("../../assets/navbar-component/close-mob-menu-btn.png")
          no-repeat center;
        background-size: contain;
      }
    }
    .links-wrapper {
      padding: 16px 20px;
      border-bottom: 1px solid rgba(155, 169, 208, 0.5);
    }
    .link {
      padding: 12px;
      padding-left: 0;
      font-size: 20px;
      line-height: 20px;
      color: #000;
      position: relative;
      cursor: pointer;
      img {
        margin-right: 14px;
        max-width: 100%;
        height: auto;
        vertical-align: bottom;
      }
      a {
        color: inherit;
        &.router-link-exact-active {
        }
        &.router-link-active {
        }
      }
      &:hover {
        color: #f05a22;
      }
      &.eb-font-black {
        color: #f05a22;
        font-family: "Helvetica-Black" !important;
      }
    }
    .translate-btns {
      padding: 16px 20px;
      // border-bottom: 1px solid rgba(155, 169, 208, 0.5);
      .translate-btn {
        padding: 12px;
        padding-left: 0;
        font-size: 16px;
        line-height: 20px;
        color: #000;
        position: relative;
        cursor: pointer;

        img {
          margin-right: 16px;
          max-width: 100%;
          height: auto;
          vertical-align: top;
        }

        span {
          display: none;
          &.active {
            display: inline-block;
          }
        }
      }
    }
    .microphone {
      position: absolute;
      display: inline-block;
      width: 49px;
      height: 97px;
      right: 40px;
    }
    &.opened {
      opacity: 1;
      visibility: visible;
      transform: scaleX(1);
      max-width: 100%;
    }
  }
  @media only screen and (max-width: 1100px) {
    // background-color: rgba(217, 224, 243, 0.8);
    // background-color: #e1e6f5;
    background-color: #d9e0f3;

    box-shadow: unset;
  }
}
</style>