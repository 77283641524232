<template>

  <div class="speaker-swiper-outer">

    <swiper ref="thumbsSwiperSpeaker" :options="speakerPageSwiperOptions" class="speaker-thumbs-wrapper" @slideChange="onThumbnailChange" @click="clickedToThumbnail">
      <swiper-slide v-for="(item, index) in Speakers" :key="index" class="custom-one-swiper-slide">
        <div class="speaker-thumb">
          <img :src="require(`@/assets/home-component/speakers/new/${item.photo}`)" />
        </div>
      </swiper-slide>
    </swiper>

  </div>

</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
export default {
  name: "SpeakersCarouselComponent",
  props: {
    Speakers: Array,
    selectedSpeaker: Object,
    scrollToID: Function,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      paginationEl: null,
    };
  },
  methods: {
    getText(key) {
      return ContentService.getContentByKey(key);
    },
    FormatSlug(name) {
      return name.split(".")[0].toLowerCase().replace(" ", "-");
    },
    onThumbnailChange(val) {
      let activeSlug = this.FormatSlug(this.Speakers[val.realIndex].photo);
      this.$emit("slideChanged", val.realIndex);
      // console.log(this.$route.fullPath, activeSlug);

      if (this.$route.fullPath != activeSlug) {
        this.$router.push({
          name: "Speaker",
          params: {
            slug: activeSlug,
          },
        });
      }
    },
    clickedToThumbnail() {
      setTimeout(() => {
        this.scrollToID("#app");
      }, 100);
    },
  },

  computed: {
    thumbsSwiperSpeaker() {
      return this.$refs.thumbsSwiperSpeaker.$swiper;
    },

    speakerPageSwiperOptions() {
      return {
        slidesPerView: "auto",
        spaceBetween: 12,
        initialSlide: this.selectedSpeaker.id - 1,
        observer: true,
        observeParents: true,
        loop: true,
        // autoplay: true,
        autoplay: false,
        slideToClickedSlide: true,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false,
        //   pauseOnMouseEnter: true,
        // },

        navigation: {
          nextEl: "",
          prevEl: "",
        },
        breakpoints: {
          // 500: {
          //   slidesPerView: 2,
          // },
          768: {
            // slidesPerView: 3,
            spaceBetween: 24,
          },
          // 1366: {
          //   slidesPerView: 4,
          // },
        },
      };
    },
  },
  beforeMount() {},
};
</script>
<style lang="scss" scoped>
.swiper-scrollbar {
  margin-top: 14px;
  background-color: #303436;
  .swiper-scrollbar-drag {
    background-color: #f05a22;
    height: 4px;
  }
}
section {
  position: relative;
  z-index: auto;
}
.speaker-swiper-outer {
  width: 100%;
  margin-left: 0px;
  position: relative;
  padding: 0px 0px;

  @media only screen and (max-width: 768px) {
    margin-left: 16px;
    padding: 0px 0px;
    margin-bottom: 0;
    .swiper-wrapper {
      margin-bottom: 0;
    }
  }
}
.swiper-slide.custom-one-swiper-slide {
  display: inline-block;
  width: 120px;
  height: auto;

  @media only screen and (max-width: 768px) {
    width: 72px;
  }
}
.swiper-slide.empty-slide {
  // width: 50px;
}
.speaker-thumb {
  cursor: pointer;
  position: relative;
  display: block;

  img {
    border-radius: 14px;
    max-width: 120px;
    height: auto;
    display: block;
    @media only screen and (max-width: 768px) {
      max-width: 72px;
    }
  }
}
.swiper-container.speaker-thumbs-wrapper {
  overflow: visible !important;
}
</style>
