import store from "@/store";
import axios from "axios";
import router from "../../router";
export default {

  fetchContent: function () {

    return axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/content/v1/promotionContent/GetPromotionContentById?promotionId=${process.env.VUE_APP_PROMOTION_ID}`
    );
  },

  setContent: function (content) {
    store.dispatch("setContent", content);
  },
  getCurrentLang: function (content) {
    return router.app._route.query.lang ?? process.env.VUE_APP_DEFAULT_LANGUAGE_CODE;
  },

  getCurrentQueries: function (content) {
    return router.app._route.query;
  },
  getContentByKey: function (key) {

    let result = "";
    const currentLanguageCode = router.app._route.query.lang ?? process.env.VUE_APP_DEFAULT_LANGUAGE_CODE;

    const allContent = store.getters.AllContent;
    const languages = store.getters.AllLanguagesExceptNeutral;
    let selectedLanguageId = null;

    if (languages !== undefined) {
      languages.map((lang) => {
        if (lang.code == currentLanguageCode) {
          selectedLanguageId = lang.id;
        }
      });
    } else {
      selectedLanguageId = 3;
    }
    //console.log("allContent ",allContent)
    if (allContent !== undefined && allContent !== null) {
      //console.log("allContent ",allContent)
      allContent.map((content) => {
        if (content.key == key) {
          content.values.map((value) => {
            if (value.languageId == selectedLanguageId) {
              result = value.value;
            }

            if (value.languageName == "Neutral" && !result) {
              result = value.value;
            }
          });
        }
      });
    }
    return result;

  }
};
