import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";
import SpeakerComponent from "@/components/speaker/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "Home",
    component: Home
  },
  {
    path: ":slug",
    name: "Speaker",
    component: SpeakerComponent,
    props: true,
  },
  {
    path: "/:slug",
    name: "Speaker",
    component: SpeakerComponent,
    props: true,
  },
  {
    path: '/*',
    redirect: { name: "Home" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

});

export default router;
